import * as React from 'react';
import { useIntl } from 'react-intl';
import "./NotFound.css"
import styled from "styled-components";
import {ComponentContainer} from "../../lib/template/ComponentContainer";

interface Props {
}

const Wrapper = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: 2rem;
  font-family: cursive;
  display: flex;
  justify-content: center;
  text-align: center;
`

const NotFound: React.FC<Props> = (props) => {
    const { formatMessage: f } = useIntl();

    return <>
        <ComponentContainer>
            <Wrapper>{f({id: 'pageNotFound'})}</Wrapper>

        </ComponentContainer>
    </>
};


export default NotFound;
