import useSWR from "swr";
import {DiseaseDto} from "../../services/mainApi";

export const useDiseases = () => {
    const key = "/diseases";
    const {data, isLoading, error} = useSWR<DiseaseDto[]>(key);


    return {
        mutationKey: key,
        data : data || [],
        isLoading,
        error
    }
}
