import { addLocale, locale } from "primereact/api";
import { UtilService } from "@xal3xfx/react-utils";
import * as Yup from "yup";
import {AxiosInstance} from "./axios";

export const configureApp = (f: any, showToast: any) => {
    UtilService.setIntlFormatter(f);
    UtilService.setOptionLabel("description");
    UtilService.setOptionValue("id");
    UtilService.setPrimeflexVersion(3);

    locale("bg");

    Yup.setLocale({
        mixed: {
            required: (params) => {
                return f({id: params.path.split('.').at(-1)}) + ' е задължително'
            }
        },
        string: {
            length: (params) => f({ id: params.path.split('.').at(-1) }) + ' трябва да съдържа поне ' + params.length + ' символа',
        },
        number: {
            positive: (params) => f({ id: params.path.split('.').at(-1) }) + ' трябва да > 0'
        }
    })

    addLocale('bg', {
        firstDayOfWeek: 1,
        dayNames: ["Неделя", "Понеделник", "Вторник", "Сряда", "Четвъртък", "Петък", "Събота"],
        dayNamesShort: ["Нд", "Пн", "Вт", "Ср", "Чет", "Пт", "Сб"],
        dayNamesMin: ["Н", "П", "В", "С", "Ч", "П", "С"],
        monthNames: ["Януари", "Февруари", "Март", "Април", "Май", "Юни", "Юли", "Август", "Септември", "Октомври", "Ноември", "Декември"],
        monthNamesShort: ["Ян", "Фев", "Март", "Апр", "Май", "Юни", "Юли", "Авг", "Сеп", "Окт", "Ноем", "Дек"],
        today: 'Днес',
        clear: 'Изчисти',
        dateFormat: 'yy-mm-dd',
        // dateFormat: 'dd.mm.yy',
        weekHeader: 'Сед'
    });

    AxiosInstance.toastHelpers = {
        showToast
    }

    AxiosInstance.intlFormatter = f;
}
