export interface ApiConfig {
    API_URL: string;
}

const mac: ApiConfig = {
    API_URL: 'http://localhost:5223/',
};

const production: ApiConfig = {
    API_URL: 'https://api.docpoint.online/',
};

const stage: ApiConfig = {
    API_URL: 'https://api.docpoint.online/',
}
const environments = {
    mac,
    production,
    stage
}

//@ts-ignore
export const apiConfig: ApiConfig = environments[process.env.REACT_APP_STAGE];
// export const apiConfig: ApiConfig = environments.stage;
