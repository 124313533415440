import * as React from 'react';
import {useEffect, useState, useRef} from 'react';
import {InputText} from "primereact/inputtext";
import {FloatLabel} from "primereact/floatlabel";
import {Button} from "primereact/button";
import "../../assets/fonts/Pangolin/Pangolin-Regular.ttf"
import {IconField} from "primereact/iconfield";
import {InputIcon} from "primereact/inputicon";
import {Api} from "../../lib/services/mainApi";
import {authenticate} from "../../lib/store/slices/authSlice";
import {useAppDispatch} from "../../lib/hooks/useAppDispatch";

interface Props {

}

const Login : React.FC<Props> = props => {
    // const {formatMessage: f} = useIntl();
    const dispatch = useAppDispatch();
    const didMountRef = useRef(false);

    const [userName, setUserName] = useState<string | undefined>(undefined);
    const [password, setPassword] = useState<string | undefined>(undefined);

    const [showPassword, setShowPassword] = useState<boolean>(false);

    const api = new Api();

    useEffect(() => {
        if(!didMountRef.current) {
            didMountRef.current = true;
        }
    }, []);

    const handleLogin = () => {
        if(!userName || !password) return;

        api.auth.login({username: userName, password})
            .then(resp => {
                if(resp) {
                    dispatch(authenticate());
                }
            }).catch()
    }

    return <div className="w-full h-full flex flex-column justify-content-center align-items-center lg:flex-row">
        <div className={"w-10 lg:w-4 lg:h-full bg-white flex flex-column justify-content-center align-items-center absolute lg:static border-round-3xl lg:border-noround"} style={{zIndex: "10"}}>
            <div className={"flex flex-wrap flex-column row-gap-4 w-full p-6 "}>
                <div style={{color: "#14b8a6", fontSize: "3rem", fontFamily: "Pangolin"}}>Welcome to
                    <div style={{fontSize: "3rem", fontWeight: "bold",  fontFamily: "Pangolin"}}>DocPoint!</div>
                </div>


                {/*<h1>Login</h1>*/}

                <FloatLabel>
                    <InputText id="username" className={"p-inputtext-lg w-full"} value={userName} onChange={(e) => setUserName(e.target.value)}/>
                    <label htmlFor="username">Username</label>
                </FloatLabel>

                <FloatLabel className={"w-full"}>
                    <IconField iconPosition="right">
                        <InputIcon className={showPassword ? "pi pi-eye-slash" : "pi pi-eye"} onClick={() => setShowPassword(!showPassword)}/>
                        <InputText id="password" className={"w-full p-inputtext-lg"} type={showPassword ? "text" : "password"} value={password} onChange={(e) => setPassword(e.target.value)} />
                    </IconField>
                    <label htmlFor="password">Password</label>
                </FloatLabel>

                <div className={"flex flex-wrap flex-row row-gap-2"}>
                    <Button label={"Login"} className={"w-full lg:w-3"} onClick={handleLogin}/>
                    <Button label={"Register"} className={"lg:ml-2 w-full lg:w-3"} outlined/>

                </div>
            </div>
        </div>

        <div className={"w-full lg:w-8 h-full login-wrapper relative"} style={{zIndex: "5"}}/>

    </div>
};

export default Login
