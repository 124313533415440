import React, {createContext, useRef} from 'react';
import {Toast} from "primereact/toast";
import {Button} from "primereact/button";
import {useIntl} from "react-intl";

export const ToastContext = createContext<any>(null);

export const ToastContextProvider = (props: any) => {
    const toast = useRef<any>();
    const confirmToast = useRef<any>();
    const {formatMessage : f} = useIntl();

    const showToast = (severity: string, summary: string | JSX.Element, detail: string | JSX.Element, sticky?: string) => {
        console.log(severity, summary, detail, sticky)
        toast.current.show({severity, summary, detail, sticky});
    }

    const useConfirmPopup = (message: string) => {
        return new Promise((resolve) => {
            confirmToast.current.show({
                severity: 'warn', sticky: true, content: (
                    <div className="p-flex p-flex-column" style={{flex: '1'}}>
                        <div className="p-text-center">
                            <i className="pi pi-exclamation-triangle" style={{fontSize: '3rem'}}></i>
                            <h4>{f({id : 'areYouSure'})}</h4>
                            <p>{message}</p>
                        </div>
                        <div className="p-grid p-fluid">
                            <div className="p-col-6">
                                <Button onClick={() => {
                                    resolve(true)
                                    confirmToast.current.clear();
                                }} type="button" label={f({id: "yes"})} className="p-button-success"/>
                            </div>
                            <div className="p-col-6">
                                <Button onClick={() => {
                                    resolve(false)
                                    confirmToast.current.clear();
                                }} type="button" label={f({id: "no"})} className="p-button-secondary"/>
                            </div>
                        </div>
                    </div>
                )
            });
        })
    }

    return <>
        <ToastContext.Provider value={{showToast, useConfirmPopup}}>
            <Toast ref={toast} style={{marginTop: "40px"}}/>
            <Toast ref={confirmToast}  position={'center'}/>
            {props.children}
        </ToastContext.Provider>
    </>
};
