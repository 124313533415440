import {useContext} from "react";
import {DialogContext} from "../context/DialogContext";

export function useDialog(): {
    isShown: boolean,
    showDialog: (data: { newContent: any, heading: any, onHideCallback?: () => void, style?: React.CSSProperties }) => void,
    hideDialog: () => void,
} {
    const dialogHelpers = useContext(DialogContext);
    return dialogHelpers;
}
