import {useIntl} from 'react-intl';
import * as React from 'react';
import {useEffect, useRef} from 'react';
import {Button} from "primereact/button";
import {Api} from "../lib/services/mainApi";
import {useAppDispatch} from "../lib/hooks/useAppDispatch";
import {logout} from "../lib/store/slices/authSlice";


interface Props {

}

const Home : React.FC<Props> = props => {
    const {formatMessage: f} = useIntl();
    const didMountRef = useRef(false);
    const api = new Api();
    const dispatch = useAppDispatch();


    useEffect(() => {
        if(!didMountRef.current) {
            didMountRef.current = true;
        }
    }, []);

    const handleLogout = () => {
        api.auth.logout()
            .then(resp => {
                dispatch(logout());
            })
    }

    return <>
        <h1>WELCOME TO THE APP</h1>
        <Button label={f({id: "test"})} onClick={handleLogout}/>
    </>
};

export default Home
