import {FormElementValues} from "@xal3xfx/react-utils";

const nameBg: FormElementValues<'text'> = {
    type: "text",
    label: 'nameBg',
    props: {},
}

const nameEn: FormElementValues<'text'> = {
    type: "text",
    label: 'nameEn',
    props: {},
}

const symptomIds: FormElementValues<'multiselect'> = {
    type: "multiselect",
    label: 'symptoms',
    props: {filter: true},
    options: []
}

export const formElements = {
    nameBg, nameEn, symptomIds
}
