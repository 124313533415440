import React from 'react';
import axios, {AxiosResponse, InternalAxiosRequestConfig} from "axios";
import {apiConfig} from "./api-config";
import {UseToast} from "./hooks/useToast";
import {useDispatch} from "react-redux";
import {logout} from "./store/slices/authSlice";

class AxiosInstance {
    static setLoading: (loading: boolean) => void = (loading: boolean) => 0;
    static toastHelpers: Pick<UseToast, 'showToast'> = {
        showToast: () => 0,
    };

    // static updateUserInfo: (userInfo: Partial<UserInfo>) => void = (userInfo: Partial<UserInfo>) => 0;
    static intlFormatter: any = () => 0

    mainAxios = axios.create({
        baseURL: apiConfig.API_URL,
        withCredentials: true
    });

    id : number = 0;

    constructor() {
        this.attachInterceptors();
        this.id = new Date().getTime();
    }

    private requestInterceptorSuccess =  (config: InternalAxiosRequestConfig<any>) => {
        // Do something before request is sent
        const isSWRRequest = config.data && config.data.isSWR;
        if(!isSWRRequest || (config.url && !config.url.includes("checkIfAuthenticated")))
            AxiosInstance.setLoading(true);

        return config;
    }

    private requestInterceptorFailure =  (error: any) => {
        //TODO: Add meaningful content
        // Do something with request error
        return Promise.reject(error);
    }


    private responseInterceptorSuccess =  (response: AxiosResponse) => {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        AxiosInstance.setLoading(false);
        if (response.data.message) {
            AxiosInstance.toastHelpers.showToast("success", AxiosInstance.intlFormatter({id: "operationSuccessful"}), AxiosInstance.intlFormatter({id: response.data.message}));
        } else if (response.data.indexOf !== undefined && response.data.indexOf('ok') === 0 && response.data.indexOf('message') !== -1) {
            const data = JSON.parse(response.data.substr(response.data.indexOf('{'), response.data.length));
            AxiosInstance.toastHelpers.showToast("success", AxiosInstance.intlFormatter({id: "operationSuccessful"}), AxiosInstance.intlFormatter({id: data.message}));
        }
        //TODO: Handle errorMessage and operationResult that are being returned from the backed (Response comes with 200 as status code, but inside the body there are the 2 fields that actually tell if there was an error
        return response;
    }

    private responseInterceptorFailure =  (err: any) => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        // AxiosInstance.setLoading(false);
        const priority = err.response.status >= 400 && err.response.status < 500 ? "warn" : "error";
        // let message = err.response.status >= 400 && err.response.status < 500 ? err.response.data.message : "internalServerError";
        let message = "internalServerError";
        if(err.response.status === 401 && !err.config.url.includes('/login')){
            const dispatch = useDispatch();
            dispatch(logout());
            // AxiosInstances.updateUserInfo({authenticated: false, roles: undefined, userId: undefined, username: undefined})
            return Promise.reject(err);
        }

        if(err.response.status === 401) {
            message = 'wrongUsernameOrPassword'
        }

        if(err.response.status === 400 && err.response.data.errors) {
            let errorsList = <ul>
                {Object.values(err.response.data.errors).map((value: any) => {
                    return value.map((error: string)=>  <li>{AxiosInstance.intlFormatter({id: error})}</li>)
                })}
            </ul>
            AxiosInstance.toastHelpers.showToast(priority, AxiosInstance.intlFormatter({id: "operationUnsuccessful"}), errorsList, true);
            return Promise.reject(err)
        }

        const sticky = err.response.data.sticky;
        AxiosInstance.toastHelpers.showToast(priority, AxiosInstance.intlFormatter({id: "operationUnsuccessful"}), AxiosInstance.intlFormatter({id: message}), sticky);
        return Promise.reject(err);
    }


    private attachInterceptors = () => {
        //Setup request interceptors
        this.mainAxios.interceptors.request.use(this.requestInterceptorSuccess, this.requestInterceptorFailure);

        //Setup response interceptors
        this.mainAxios.interceptors.response.use(this.responseInterceptorSuccess, this.responseInterceptorFailure);
    }

}


const axiosInstances = new AxiosInstance();
export {AxiosInstance, axiosInstances};
