import {useContext} from "react";
import {ToastContext} from "../context/ToastContext";

export interface UseToast {
    showToast : (severity: "success" | "warn" | "error" | "info", summary: string | JSX.Element, detail: string | JSX.Element, sticky?: boolean) => void;
    useConfirmPopup : (message: string) => Promise<boolean>;
    showCreatedToast: (f : any, who: string, definiteArticle : '' | 'а' | 'o', success: boolean) => void;
    showUpdatedToast: (f : any, who: string, definiteArticle : '' | 'а' | 'o', success: boolean) => void;
    showDeletedToast: (f : any, who: string, definiteArticle : '' | 'а' | 'o', success: boolean) => void;
}

export function useToast() : UseToast{
    const toastHelpers = useContext(ToastContext);
    const showCreatedToast = (f : any, who: string, definiteArticle : '' | 'а' | 'o', success: boolean) => {
        const severity = success ? 'success' : 'warn';
        const summary = f({id: success ? 'operationSuccessful' : 'operationUnsuccessful'});
        const detail = f({id: success ? 'createdSuccessfully' : 'createdUnsuccessfully'}, {who, definiteArticle});
        toastHelpers.showToast(severity,summary, detail);
    }

    const showUpdatedToast = (f : any, who: string, definiteArticle : '' | 'а' | 'o', success: boolean) => {
        const severity = success ? 'success' : 'warn';
        const summary = f({id: success ? 'operationSuccessful' : 'operationUnsuccessful'});
        const detail = f({id: success ? 'updatedSuccessfully' : 'updatedUnsuccessfully'}, {who, definiteArticle});
        toastHelpers.showToast(severity,summary, detail);
    }

    const showDeletedToast = (f : any, who: string, definiteArticle : '' | 'а' | 'o', success: boolean) => {
        const severity = success ? 'success' : 'warn';
        const summary = f({id: success ? 'operationSuccessful' : 'operationUnsuccessful'});
        const detail = f({id: success ? 'deletedSuccessfully' : 'deletedUnsuccessfully'}, {who, definiteArticle});
        toastHelpers.showToast(severity,summary, detail);
    }
    return {...toastHelpers, showCreatedToast, showDeletedToast, showUpdatedToast};
}
