import React, {createContext, useEffect, useRef, useState} from 'react';
import Preloader from "../template/preloader/Preloader";

export const PreloaderContext = createContext<any>(null);

export const PreloaderContextProvider = (props: any) => {
    const [renderLoading, setRenderLoading] = useState(false);
    const [rerender, setRerender] = useState(new Date().getTime())
    const loadingElementsCountRef = useRef(0);

    const setLoading = (flag: boolean) => {
        if (flag) loadingElementsCountRef.current = loadingElementsCountRef.current + 1
        else loadingElementsCountRef.current = loadingElementsCountRef.current - 1;
        setRerender(new Date().getTime());
    }

    useEffect(() => {
    }, [renderLoading]);


    useEffect(() => {
        if (loadingElementsCountRef.current > 0) setRenderLoading(true);
        if (loadingElementsCountRef.current === 0) setRenderLoading(false);
    }, [rerender]);

    const clearBlur = () => {
        const root = document.getElementById("root");
        if (root) {
            root.childNodes.forEach(el => {
                //@ts-ignore
                if(el && el.style) {
                    //@ts-ignore
                    el.style.removeProperty("filter");
                }

            })
        }
        return null;
    }

    return <>
        <PreloaderContext.Provider value={{setLoading}}>
            {
                renderLoading ?
                    <Preloader/>
                    :
                    clearBlur()
            }
            {props.children}
        </PreloaderContext.Provider>
    </>
};
