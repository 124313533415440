import { createSlice } from "@reduxjs/toolkit"
import {UserInfo} from "../../types/types";

const initialState : UserInfo = {
    authenticated: false
}


export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        authenticate: (state) => {
            state.authenticated = true;
        },
        logout: (state) => {
            state.authenticated = false;
        }
    }
})

export const {authenticate, logout} = authSlice.actions;

export default authSlice.reducer;
