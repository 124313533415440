import {useIntl} from 'react-intl';
import * as React from 'react';
import {useEffect, useState, useRef} from 'react';
import {DynamicForm} from "@xal3xfx/react-utils";
import {formElements} from "./disease-form";
import {Api, CreateDiseaseDto, DiseaseDto, UpdateDiseaseDto} from "../../../lib/services/mainApi";
import {validationSchema} from "./validation";
import {useToast} from "../../../lib/hooks/useToast";
import {useDiseasesDropdown} from "../../../lib/hooks/diseases/useDiseasesDropdown";
import {IDropdownOption} from "@xal3xfx/react-utils/dist/util-service";
import {Chip, ChipRemoveEvent} from "primereact/chip";
import {useSymptomsDropdown} from "../../../lib/hooks/symptoms/useSymptomsDropdown";

interface Props {
    createdCb?: () => void;
    selectedDisease?: DiseaseDto;
}
const initialValue: CreateDiseaseDto = {
    nameBg: "",
    nameEn: "",
    symptomIds: []
}


const CreateOrUpdateDisease : React.FC<Props> = props => {
    const {formatMessage: f} = useIntl();
    const didMountRef = useRef(false);
    const api = new Api();
    const [formData, setFormData] = useState<CreateDiseaseDto | UpdateDiseaseDto>({...initialValue});
    const [formElementsState, setFormElementsState] = useState({...formElements})
    const [selectedSymptoms, setSelectedSymptoms] = useState<number[]>();
    const {showToast} = useToast();

    const {data: symptomOptions} = useSymptomsDropdown();

    const formikRef = useRef<any>();

    useEffect(() => {
        if(!didMountRef.current) {
            didMountRef.current = true;
        }
    }, []);

    useEffect(() => {
        setFormData({id: props.selectedDisease?.id,symptomIds: props.selectedDisease?.symptoms?.map(el => el.id!), nameBg: props.selectedDisease?.nameBg, nameEn: props.selectedDisease?.nameEn})
        setSelectedSymptoms(props.selectedDisease?.symptoms?.map(d => d.id!));
    }, [props.selectedDisease])

    useEffect(() => {
        if(!symptomOptions) return;
        const tempFormElements = {...formElementsState};
        tempFormElements.symptomIds.options = symptomOptions as IDropdownOption[];
        setFormElementsState(tempFormElements)
    }, [symptomOptions])


    const handleCreate = (data: CreateDiseaseDto) => {
        return new Promise<boolean>((resolve, reject) => {
            api.diseases.createDisease(data)
                .then(resp => {
                    if (resp.data) {
                        showToast('success', '', f({id: 'diseaseCratedSuccessfully'}));
                        resolve(true);
                        props.createdCb && props.createdCb();
                    } else resolve(false);
                }).catch(reject)
        })
    }

    const handleUpdate = (data: UpdateDiseaseDto) => {
        return new Promise<boolean>((resolve, reject) => {
            api.diseases.updateDisease(data)
                .then(resp => {
                    if (resp.data) {
                        showToast('success', '', f({id: 'diseaseUpdatedSuccessfully'}));
                        resolve(true);
                        props.createdCb && props.createdCb();
                    } else resolve(false);
                }).catch(reject)
        })
    }

    const onFieldChangeCb = (field: string, value: any) => {
        if(field === "symptomIds") {
            setSelectedSymptoms(value);
        }
    }

    const handleRemoveDisease = (e: ChipRemoveEvent) => {
        const selectedDiseaseId = symptomOptions.find(el => el.description === e.value)?.id;
        if(formikRef.current) {
            const newIds = formikRef.current.values.symptomIds.filter((d: number) => d !== selectedDiseaseId);
            formikRef.current.setFieldValue("symptomIds", newIds);
        }
    }

    return <>
        <DynamicForm formElements={formElements} isUpdate={!!props.selectedDisease} onCreate={handleCreate} onUpdate={handleUpdate}
                     validationSchema={validationSchema}
                     initialValues={formData} fieldOrder={['nameBg', 'nameEn', 'symptomIds']}
                     onFieldChangeCallback={onFieldChangeCb}
                     onCancelUpdate={() => 0}
                     setFormikRef={(formik: any) => formikRef.current = formik}
                     rowClassName={'col-12 md:col-6 mt-3'}>

            <div className={"col-12"}>
                {selectedSymptoms?.map(symptomId => {
                    const selectedSymptom = symptomOptions.find(el => el.id === symptomId);
                    if(selectedSymptom)
                        return <Chip key={selectedSymptom.id} className={"p-2 m-2"} label={selectedSymptom.description!} removable onRemove={handleRemoveDisease}/>
                    else return  <></>
                })}
            </div>
        </DynamicForm>

    </>
};

export default CreateOrUpdateDisease
