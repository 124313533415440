import * as React from 'react';
import {App} from "../App";
import {IntlProvider} from 'react-intl';
import messages_bg from "./textProperties/bg.json"
import {ToastContextProvider} from "./context/ToastContext";
import {DialogContextProvider} from "./context/DialogContext";
import {Key, SWRConfig, SWRHook} from "swr";
import {BrowserRouter} from "react-router-dom";
import {PrimeReactProvider} from "primereact/api";
import {Provider} from "react-redux";

import {axiosInstances} from "./axios";
import {ConfirmDialogContextProvider} from "./context/ConfirmDialogContext";
import {store} from "./store/store";

interface Props {
}

export const AppProxy: React.FC<Props> = (props) => {
    const messages = {'bg': messages_bg}

    const proxyMiddleware = (next: SWRHook) => {
        return (key: Key, fetcher: any, config: any) => {
            const extendedFetcher = (...args: any) => {
                args.push(true);
                return fetcher(args);
            }
            return next(key, extendedFetcher, config);
        }
    }


    return <>
        {/* @ts-ignore*/}
        <IntlProvider locale={"bg-BG"} messages={messages.bg} defaultLocale={"bg-BG"}>
            <ToastContextProvider>
                {/*<UserContextProvider>*/}
                    <SWRConfig value={{
                        fetcher: async ([url, isSWR]) => {
                            const response = await axiosInstances.mainAxios(url, {data: {isSWR}});
                            return response.data;
                        },
                        loadingTimeout: 500,
                        onLoadingSlow: (key, config) => {
                            // console.log('loading slow')
                            //If going to use this, the whole concept of the PreloderContext should be reconsidered
                            // setLoading(true);
                        },
                        use: [proxyMiddleware]
                    }}>

                        <BrowserRouter>
                            <PrimeReactProvider value={{ripple: true}}>
                                <Provider store={store}>
                                    <ConfirmDialogContextProvider>
                                        <DialogContextProvider>
                                            {/*<OperationsToolbarContextProvider>*/}
                                                <App/>
                                            {/*</OperationsToolbarContextProvider>*/}
                                        </DialogContextProvider>
                                    </ConfirmDialogContextProvider>
                                </Provider>
                            </PrimeReactProvider>
                        </BrowserRouter>

                    </SWRConfig>
                {/*</UserContextProvider>*/}
            </ToastContextProvider>

        </IntlProvider>
    </>
};
