import useSWR from "swr";
import {DropdownItem, SymptomDto} from "../../services/mainApi";

export const useSymptomsDropdown = () => {
    const key = "/symptoms/dropdown";
    const {data, isLoading, error} = useSWR<DropdownItem[]>(key);


    return {
        mutationKey: key,
        data : data || [],
        isLoading,
        error
    }
}
