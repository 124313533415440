import React from "react";
import { useIntl } from "react-intl"
import { Card } from "primereact/card";

interface ComponentContainerProps {
    componentName?: string;
    children?: React.ReactNode;
    style?: React.CSSProperties
}

export const ComponentContainer: React.FC<ComponentContainerProps> = (props) => {
    const { formatMessage: f } = useIntl();

    return <>
        <div className={'component-container'} style={{ ...props.style, padding: '1rem' }}>
            {props.componentName ? <h1 style={{ textAlign: 'center', marginBottom: '1rem' }}>{props.componentName}</h1> : null}
            {props.children}
        </div>
    </>
}

