import {useIntl} from 'react-intl';
import * as React from 'react';
import {useEffect, useRef} from 'react';
import {ComponentContainer} from "../../lib/template/ComponentContainer";
import {useDiseases} from "../../lib/hooks/diseases/useDiseases";
import {Api, DiseaseDto} from "../../lib/services/mainApi";
import {DataView} from "primereact/dataview";
import {Button} from "primereact/button";
import {useDialog} from "../../lib/hooks/useDialog";
import CreateOrUpdateDisease from "./form/CreateOrUpdateDisease";
import {mutate} from "swr";
import {useConfirmDialog} from "../../lib/hooks/useConfirmDialog";
import {useToast} from "../../lib/hooks/useToast";

interface Props {

}

const Diseases : React.FC<Props> = props => {
    const {formatMessage: f} = useIntl();
    const didMountRef = useRef(false);
    const {showDialog, hideDialog} = useDialog();
    const {showConfirmDialog} = useConfirmDialog();
    const {showToast} = useToast();


    const {data: diseases, mutationKey} = useDiseases()
    const api = new Api();

    useEffect(() => {
        if(!didMountRef.current) {
            didMountRef.current = true;
        }
    }, []);

    const getItemTemplate = (disease: DiseaseDto) => {
        return <div className="col-12 xl:col-4 p-2" key={disease.id} style={{minHeight: "5rem"}}>
            <div className="p-2 border-1 surface-border surface-card border-round h-full">

                    <div className="flex align-items-center gap-2">
                        <span className="font-semibold">{disease.nameBg}</span>
                    </div>
                    <div className={"flex justify-content-start align-items-center ml-auto"}>
                        <span>{disease.nameEn || f({id: "noEnTranslation"})}</span>
                        <Button className={"ml-auto"} text icon="pi pi-pencil" onClick={() => handleEdit(disease)}/>
                        <Button text icon="pi pi-trash" className={"p-button-danger"} onClick={() => handleDelete(disease)}/>
                    </div>
            </div>
        </div>
    }

    const handleEdit = (disease: DiseaseDto) => {
        showDialog({
            newContent: <CreateOrUpdateDisease selectedDisease={disease} createdCb={() => {
                hideDialog()
                mutate(mutationKey);
            }}/>,
            heading: <h3>{f({id: "updateDisease"})}</h3>,
            style: {maxWidth: "95vw", minWidth: "60vw"}
        })
    }

    const handleDelete = (disease: DiseaseDto) => {
        showConfirmDialog({body: f({id: "confirmDeleteDisease"}, {diseaseName: disease?.nameBg})}).then(answer => {
            if (answer) {
                api.diseases.deleteDisease(disease.id!).then(() => {
                    showToast("success", "", f({id: "diseaseDeletedSuccessfully"}));
                    mutate(mutationKey)
                });
            }
        })
    }

    const listTemplate = (diseases: DiseaseDto[], layout: "grid" | "list") => {
        return <div className="grid grid-nogutter">{diseases.map((product, index) => getItemTemplate(product))}</div>;
    };

    const showCreateDisease = () => {
        showDialog({
            newContent: <CreateOrUpdateDisease createdCb={() => {
                hideDialog()
                mutate(mutationKey);
            }}/>,
            heading: <h3>{f({id: "addDisease"})}</h3>,
            style: {maxWidth: "95vw", minWidth: "60vw"}
        })
    }

    return <>
        <ComponentContainer componentName={f({id: "diseases"})}>
            <Button label={f({id: "addDisease"})} icon={"pi pi-plus"} className={"m-2"} onClick={showCreateDisease}/>
            <DataView value={[...diseases]} layout={"grid"} listTemplate={listTemplate}/>
        </ComponentContainer>
    </>
};

export default Diseases
