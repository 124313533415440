import React, {createContext, useEffect, useRef, useState} from 'react';
import {Dialog} from "primereact/dialog";

export const DialogContext = createContext<any>(null);
export const DialogContextProvider = (props: any) => {
    const [isShown, setIsShown] = useState(false);
    const [content, setContent] = useState<any>(null);
    const [heading, setHeading] = useState('');
    const [dialogStyle, setDialogStyle] = useState<React.CSSProperties>()

    const didMountRef = useRef(false);

    const onHideCallbackRef = useRef<any>(null);

    const showDialog = ({newContent, heading, onHideCallback, style} : { newContent: any, heading: any, onHideCallback: () => void, style?: React.CSSProperties }) => {
        setContent(newContent);
        setHeading(heading);
        setDialogStyle(style)
        onHideCallbackRef.current = onHideCallback;
        setIsShown(true);
    }

    useEffect(() => {
        if (!didMountRef.current) {
            didMountRef.current = true;
        }
    }, []);

    const hideDialog = () => {
        setIsShown(false);
        console.log("HIDING DIALOG, the ref is: ", onHideCallbackRef.current);
        if (isShown && onHideCallbackRef.current) {
            onHideCallbackRef.current();
        }
    }

    useEffect(() => {
        if (!isShown && onHideCallbackRef.current) {
            onHideCallbackRef.current();
        }
    }, [isShown, onHideCallbackRef]);

    return <>
        <DialogContext.Provider
            value={{showDialog: showDialog, hideDialog: hideDialog, isShown}}>
            <Dialog resizable visible={isShown} onHide={hideDialog} blockScroll header={heading} maximizable={true} breakpoints={{ '960px': '65vw', '641px': '100vw' }} style={dialogStyle}>
                <div className={"mt-3"}>
                    {content}
                </div>
            </Dialog>
            {props.children}
        </DialogContext.Provider>
    </>
};
